<template>
  <div
    role="alert"
    :class="[
      { 'border-neutral-50 bg-neutral-50 text-neutral-800 p-3': neutral },
      { 'border-petrol-500 bg-petrol-50 text-petrol-500 p-3': info },
      { 'border-cta-700 bg-cta-50 text-cta-700 p-3': error },
      { 'border-green-500 bg-green-100 text-green-500 p-3': success },
      { 'border-green-500 bg-green-50 text-green-500 p-4': successV2 },
      'border rounded-xl']"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  design: {
    type: String,
    default: 'neutral'
  }
})
const neutral = computed(() => props.design === 'neutral')
const info = computed(() => props.design === 'info')
const error = computed(() => props.design === 'error')
const success = computed(() => props.design === 'success')
const successV2 = computed(() => props.design === 'successV2')

</script>
